import { IGRECalcRepository } from "../repository/igre-calc-repo";
import { envConfig } from "../config";
import { getRefiResultDivPayload, getSaleResultDivPayload, getSaleEmailPayload, getRefiEmailPayload, getSaleRefiTextPayload, getSalePrintPayload, getRefiPrintPayload } from "../validate/validate";
import { statesDynamicHTML, countyDynamicHTML, showLoader, hideLoader, showHideAddlSaleActions, showHideAddlRefiActions, reIssueYearHide, showSuccessAlert, resetEmailTextModal, validateEmail, showerrorLabel } from '../utils/calc-util'
export class IGRECalcService {

    constructor() {
        this.calculatorPrintURL = envConfig.calculatorPrintURL;
        this.repository = new IGRECalcRepository();
        this.reIssueDetails = [];
        this.states = [];
    }

    getReissueDetails = async () => {
        try {
            this.reIssueDetails = await this.repository.getReissueYear();
            if (this.reIssueDetails?.data)
                this.reIssueDetails = this.reIssueDetails.data;
        }
        catch (err) {
            this.showErrorMessage(err, "Unable to get reIssue Year, Something went wrong");
        }
    }
    setReissueYear = async () => {
        let saleStateDD = document.getElementById("igre-salestates");
        let saleCountyDD = document.getElementById("igre-salecounty");
        let reIssueYearId = document.getElementById("igre-reissueyear");
        let reIssueYearLabel = document.getElementById("igre-reissueyear-label");
        let stateName = saleStateDD.options[saleStateDD.selectedIndex].text;
        reIssueYearHide();
        let stateCode = this.states.filter(function (states) { return states.statename == stateName });

        if (stateCode?.length > 0) {
            const reIssueYearStateDetail = this.reIssueDetails.filter(function (reIssueDetail) { return reIssueDetail.state == stateCode[0].statecode });
            if (reIssueYearStateDetail?.length > 0) {
                if (reIssueYearStateDetail[0].sale && (reIssueYearStateDetail[0].counties == "All" || reIssueYearStateDetail[0].counties.toLowerCase() == saleCountyDD.options[saleCountyDD.selectedIndex].text.toLowerCase())) {
                    reIssueYearId.style.display = 'block';
                    reIssueYearLabel.innerHTML = reIssueYearStateDetail[0].reissueyr_label;
                }
            }
        }
    }
    setRefinanceReissueYear = async () => {
        let refiStateDD = document.getElementById("igre-refistates");
        let refiCountyDD = document.getElementById("igre-reficounty");
        let reIssueYearId = document.getElementById("igre-refi-reissueyear");
        let reIssueYearLabel = document.getElementById("igre-refi-reissueyear-label");
        let stateName = refiStateDD.options[refiStateDD.selectedIndex].text;
        reIssueYearHide();
        let stateCode = this.states.filter(function (states) { return states.statename == stateName });
        if (stateCode?.length > 0) {
            const reIssueYearStateDetail = this.reIssueDetails.filter(function (reIssueDetail) { return reIssueDetail.state == stateCode[0].statecode });
            if (reIssueYearStateDetail?.length > 0) {
                if (reIssueYearStateDetail[0].refi && (reIssueYearStateDetail[0].counties == "All" || reIssueYearStateDetail[0].counties.toLowerCase() == refiCountyDD.options[refiCountyDD.selectedIndex].text.toLowerCase())) {
                    reIssueYearId.style.display = 'block';
                    reIssueYearLabel.innerHTML = reIssueYearStateDetail[0].reissueyr_label;
                }
            }
        }
    }
    populateStates = async () => {
        try {
            showLoader();
            let states = await this.repository.getStates();
            if (states?.data) {
                this.states = states.data;
                let saleStateDD = document.getElementById("igre-salestates");
                let refiStateDD = document.getElementById("igre-refistates");
                saleStateDD.innerHTML = statesDynamicHTML(this.states);
                refiStateDD.innerHTML = statesDynamicHTML(this.states);
            }
            hideLoader();
        }
        catch (err) {
            this.showErrorMessage(err, "Unable to get the states, Something went wrong");
            hideLoader();
        }
    }

    populateSaleCounties = async (county) => {
        try {
            let stateDD = document.getElementById("igre-salestates");
            let countyDD = document.getElementById("igre-salecounty");
            let stateId = stateDD.value;
            showLoader();
            const counties = await this.repository.getCounties(stateId);
            countyDD.innerHTML = countyDynamicHTML(counties);
            if (counties?.data) {
                this.selectedIndexStateCounty(countyDD, county)
                this.setReissueYear();
            }
            hideLoader();
        }
        catch (err) {
            this.showErrorMessage(err, "Unable to get the Counties, Something went wrong");
            hideLoader();
        }
    }

    populateRefiCounties = async (county) => {
        try {
            let stateDD = document.getElementById("igre-refistates");
            let countyDD = document.getElementById("igre-reficounty");
            let stateId = stateDD.value;
            showLoader();
            const counties = await this.repository.getCounties(stateId);
            if (counties?.data) {
                countyDD.innerHTML = countyDynamicHTML(counties);
                this.selectedIndexStateCounty(countyDD, county);
                reIssueYearHide();
            }
            hideLoader();
        } catch (err) {
            this.showErrorMessage(err, "Unable to get the Counties, Something went wrong");
            hideLoader();
        }
    }
    calculateSale = async () => {
        try {
            let payload = getSaleResultDivPayload();
            let salecalculateResultDetails = document.getElementById("igre-calculateSaleResultDetails");
            showLoader();
            const saleresult = await this.repository.getSalesResultDetails(payload);
            if (saleresult?.data) {
                this.stateCountyselectSaleRefi(payload, saleresult);
                salecalculateResultDetails.innerHTML = saleresult.data
                let actionsShowHide = true;
                showHideAddlSaleActions(actionsShowHide);
            }
            hideLoader();
        }
        catch (err) {
            hideLoader();
            this.showErrorMessage(err, "Unable to get the calculate, Something went wrong");
        }
    }
    selectedIndexStateCounty = async (saleState, saleResultState) => {
        let opts = saleState.options;
        for (let i = 0; i < opts.length; i++) {
            if (opts[i].text == saleResultState) {
                saleState.selectedIndex = i;
                break;
            }
        }
    }
    calculateRefinance = async () => {
        try {
            let payload = getRefiResultDivPayload();
            let reficalculateResultDetails = document.getElementById("igre-calculateRefiResultDetails");
            showLoader();
            const refiresult = await this.repository.getRefiResultDetails(payload);
            if (refiresult?.data) {
                this.stateCountyselectSaleRefi(payload, refiresult);
                reficalculateResultDetails.innerHTML = refiresult.data;
                let actionsShowHide = true;
                showHideAddlRefiActions(actionsShowHide);
            }
            hideLoader();

        }
        catch (err) {
            hideLoader();
            this.showErrorMessage(err, "Unable to get the calculate, Something went wrong");
        }
    }
    stateCountyselectSaleRefi = async (payload, saleRefiResult) => {
        if (payload.salePrice) {
            let saleStateDD = document.getElementById("igre-salestates");
            if (payload.stateName !== saleRefiResult.state) {
                this.selectedIndexStateCounty(saleStateDD, saleRefiResult.state)
            }
            if (payload.stateName !== saleRefiResult.state || payload.countyName !== saleRefiResult.county) {
                await this.populateSaleCounties(saleRefiResult.county);
            }
        }
        else {
            let refiStateDD = document.getElementById("igre-refistates");
            if (payload.stateName !== saleRefiResult.state) {
                this.selectedIndexStateCounty(refiStateDD, saleRefiResult.state)
            }
            if (payload.stateName !== saleRefiResult.state || payload.countyName !== saleRefiResult.county) {
                await this.populateRefiCounties(saleRefiResult.county);
            }
        }
    }
    sendSaleEmail = async () => {
        try {
            let payload = getSaleEmailPayload();
            let saleEmailResponse = await this.repository.getEstimateSaleEmail(payload);
            if (saleEmailResponse?.message) {
                showSuccessAlert(saleEmailResponse.message);
            }
            hideLoader();
        }
        catch (err) {
            hideLoader();
            this.showErrorMessage(err, "Unable to send a mail, Something went wrong");
        }
    }
    sendRefiEmail = async () => {
        try {
            let payload = getRefiEmailPayload();
            let refiEmailResponse = await this.repository.getEstimateRefiEmail(payload);
            if (refiEmailResponse?.message) {
                showSuccessAlert(refiEmailResponse.message);
            }
            hideLoader();

        }
        catch (err) {
            hideLoader();
            this.showErrorMessage(err, "Unable to send a mail, Something went wrong");
        }
    }
    sendTextMessageSaleRefi = async () => {
        try {
            let payload = getSaleRefiTextPayload();
            let saleRefiTextMessge = await this.repository.getsendTextMessageSaleRefi(payload);
            if (saleRefiTextMessge?.message) {
                showSuccessAlert(saleRefiTextMessge.message.replace(/[\[\]']+/g, ""));
            }
            hideLoader();
        }
        catch (err) {
            hideLoader();
            this.showErrorMessage(err, "Unable to send a message, Something went wrong");
        }
    }
    getSalePrintDetails = async () => {
        try {

            let payload = getSalePrintPayload();
            showLoader();
            let printURL = this.calculatorPrintURL + "?";
            for (let entry in payload) {
                printURL += entry + '=' + encodeURIComponent(payload[entry]) + '&';
            }
            // remove last '&'
            printURL = printURL.substring(0, printURL.length - 1);
            console.log(printURL);
            try {
                let printWindow = window.open(printURL, '', 'height=1000, width=1000');
                printWindow.focus();
            } catch (err) {
                this.showErrorMessage(err, "Unable to open print window. Please check your browser's popup blocker");
            }
            hideLoader();
        }
        catch (err) {
            this.showErrorMessage(err, "Unable to print the document, Something went wrong");
        }
    }
    getRefiPrintDetails = async () => {
        try {
            let payload = getRefiPrintPayload();
            showLoader();
            let printURL = this.calculatorPrintURL + "?";
            for (let entry in payload) {
                printURL += entry + '=' + encodeURIComponent(payload[entry]) + '&';
            }
            // remove last '&'
            printURL = printURL.substring(0, printURL.length - 1);
            console.log(printURL);
            try {
                let printWindow = window.open(printURL, '', 'height=1000, width=1000');
                printWindow.focus();
            } catch (err) {
                
                this.showErrorMessage(err, "Unable to open print window. Please check your browser's popup blocker");
            }
            hideLoader();
        }
        catch (err) {
            this.showErrorMessage(err, "Unable to print the document, Something went wrong");
        }
    }

    sendEmailSaleRefinance = async () => {
        let saleTabButton = document.getElementById("igre-sale-tab");
        let refiTabButton = document.getElementById("igre-refi-tab");
        let emailModalPopUp = document.getElementById("igre-emailmodal");
        let emailAddress = document.getElementById("igre-email");
        let emailerrormsg = document.getElementById("igre-emailerrormsg");
        let saleEmailList = [];
        emailAddress = emailAddress.value;
        document.querySelectorAll('.igre-all-mail .igre-email-ids .igre-cancel-emailto').forEach((email) => {
            saleEmailList.push(email.innerText);
        });
        if (saleEmailList.length <= 5 && saleEmailList.length != 0) {
            if (emailAddress != "") {
                if (validateEmail(emailAddress)) {
                    showLoader();
                    if (saleTabButton.value == "sale" && refiTabButton.value == "")
                        await this.sendSaleEmail()
                    else
                        await this.sendRefiEmail();
                    resetEmailTextModal();
                    emailModalPopUp.style.display = "none";
                }
            }
            else {
                showLoader();
                if (saleTabButton.value == "sale" && refiTabButton.value == "")
                    await this.sendSaleEmail()
                else
                    await this.sendRefiEmail();
                resetEmailTextModal();
                emailModalPopUp.style.display = "none";
            }
            hideLoader();
        }
        else if (saleEmailList.length == 0 && emailAddress == "") {
            showerrorLabel();
            emailerrormsg.innerHTML = "Please enter the Email Address";
        }
        else if (saleEmailList.length > 5) {
            showerrorLabel();
            emailerrormsg.innerHTML = "Maximum 5 emails are allowed.";
        }

    }
    sendTextMsgSaleRefinance = async () => {
        let mobileModalPopUp = document.getElementById("igre-mobilemodal");
        let mobile = document.getElementById("igre-mobile")
        let mobileerrormsg = document.getElementById("igre-mobileerrormsg");
        let messageField = document.getElementById("igre-mobilesubject");
        mobile = mobile.value;
        let phoneNumberList = [];
        document.querySelectorAll('.igre-all-mobile-number .igre-mobile-number-ids .igre-cancel-mobile-number').forEach((number, index) => {
            phoneNumberList.push(number.innerText);
        });
        if (phoneNumberList.length <= 5 && phoneNumberList.length != 0 && messageField.value.trim() != "") {
            if (mobile != "") {
                if (validateMobileNumber(mobile)) {
                    showLoader();
                    await this.sendTextMessageSaleRefi();
                    mobileModalPopUp.style.display = "none";
                    resetEmailTextModal();
                }
            }
            else {
                showLoader();
                await this.sendTextMessageSaleRefi();
                mobileModalPopUp.style.display = "none";
                resetEmailTextModal();
            }
            hideLoader();
        }
        else if (phoneNumberList.length == 0 && mobile == "") {
            showerrorLabel();
            mobileerrormsg.innerHTML = "Please enter the mobile number";
        }
        else if (phoneNumberList.length > 5) {
            showerrorLabel();
            mobileerrormsg.innerHTML = "Maximum 5 phone numbers are allowed.";
        }
    }

    showErrorMessage(err, message) {
        hideLoader();
        console.log(err);
        let alertError = document.getElementById('igre-alert-error');
        alertError.classList.remove("igre-nodisplay");
        let alertErrorMessage = document.getElementById("igre-alert-errormessage");
        alertErrorMessage.innerHTML = message;
    }
}